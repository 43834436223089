import React from "react";
import Experience from "../Items/Experience";

const experiencesData = [
  {
    id: 1,
    year: "Jun-2022 - Jul-2023",
    jobtitle: "Business IT Support Specialist",
    jobcompany: "Vodafone Group Albania",
    content:
      "Lorem ipsum dolor sit amet consectetuer adipiscing elit aenean commodo ligula eget dolor aenean massa.",
  },
  {
    id: 2,
    year: "Sept-2021 - Jun-2022",
    jobtitle: "IT Support Specialist",
    jobcompany: "Vodafone Group Albania",
    content:
      "Lorem ipsum dolor sit amet consectetuer adipiscing elit aenean commodo ligula eget dolor aenean massa.",
  },
  {
    id: 3,
    year: "March-2021 - Sept-2021",
    jobtitle: "IT Specialist",
    jobcompany: "Arredim Albania",
    content:
      "Lorem ipsum dolor sit amet consectetuer adipiscing elit aenean commodo ligula eget dolor aenean massa.",
  },
  {
    id: 4,
    year: "2020 - 2021",
    jobtitle: "IT Specialist",
    jobcompany: "Digicom Albania",
    content:
      "Lorem ipsum dolor sit amet consectetuer adipiscing elit aenean commodo ligula eget dolor aenean massa.",
  },
  {
    id: 5,
    year: "2018 - 2020",
    jobtitle: "IT Specialist",
    jobcompany: "Jon Construction & Solution",
    content:
      "Lorem ipsum dolor sit amet consectetuer adipiscing elit aenean commodo ligula eget dolor aenean massa.",
  },
];

function Experiences() {
  return (
    <div className="timeline">
      {experiencesData.map((experience) => (
        <Experience experience={experience} key={experience.id} />
      ))}
      <span className="timeline-line"></span>
    </div>
  );
}

export default Experiences;
