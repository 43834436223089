import React from "react";
import Education from "../Items/Education";

const educationsData = [
    {
        id: 1,
        year: "2018 - 2020",
        degree: "High Technicial of Computer Systems",
        institution: "Univeristy Collge of Business",
        content: "Obtained a High Technician of Computer Systems degree through professional studies at University College of Business."
    },
    {
        id: 2,
        year: "2015 - 2018",
        degree: "High School Diploma",
        institution: "Nesim Kaçani",
        content: "Started and Finished Highschool to Nesim Kaçani Miras,Korçe Albania."
    }
];

function Educations() {
    return (
        <div className="timeline">
            {educationsData.map((education) => (
                <Education education={education} key={education.id} />
            ))}
            <span className="timeline-line"></span>
        </div>
    );
}

export default Educations;