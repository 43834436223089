import React from "react";

function Experience({ experience: { year, jobtitle, content, jobcompany } }) {
  return (
    <div className="entry">
      <div className="title">
        <span>{year}</span>
      </div>
      <div className="body">
        <h3 className="mt-0">{jobtitle}</h3>
        <h6 className="mt-1">{jobcompany}</h6>
        <p>{content}</p>
      </div>
    </div>
  );
}

export default Experience;
