import React from "react";

const contactData = {
  phone: ["+355 69 222 5910"],
  email: ["hello@erandisuli.dev"],
  location: "Astir, Tirana Albania, 1065",
};
function Contact() {
  return (
    <div className="row">
      <div className="col-md-4 mb-4 mb-md-0">
        <div className="contact-info mb-5">
          <i className="icon-phone"></i>
          <div className="details">
            <h5>Phone</h5>
            {contactData.phone.map((singlePhone, index) => (
              <span key={index}>{singlePhone}</span>
            ))}
          </div>
        </div>
        <div className="contact-info mb-5">
          <i className="icon-envelope"></i>
          <div className="details">
            <h5>Email address</h5>
            {contactData.email.map((singleEmail, index) => (
              <span key={index}>{singleEmail}</span>
            ))}
          </div>
        </div>
        <div className="contact-info">
          <i className="icon-location-pin"></i>
          <div className="details">
            <h5>Location</h5>
            <span>{contactData.location}</span>
          </div>
        </div>
      </div>

      <div className="col-md-8">
        <iframe title="contact-form-from-perfex" width="500" height="420" src="https://business.sulishpk.com/forms/wtl/684df154134284af8d054e8534d1b4da" frameBorder="0" sandbox="allow-top-navigation allow-forms allow-scripts allow-same-origin allow-popups" allowFullScreen></iframe>
      </div>
    </div>
  );
}

export default Contact;
