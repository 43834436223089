import React from "react";
import { Link as ScrollLink } from "react-scroll";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const aboutData = {
  cvpath: "media/Erandi-Suli-CV.pdf",
  image: "images/AboutMe.png",
  phone: "+355 69 222 5810",
  location: "Tirana, Albania",
  birthday: "15 April 2000",
  email: "hello@erandisuli.dev",
  aboutMe:
    "A highly motivated IT professional with a passion for technology and a proven track record in back-end web development, system administration, and networking.  Eager to leverage technical skills and problem-solving abilities to contribute to a dynamic team.",
};

function About() {
  return (
    <div className="row">
      <div className="col-md-3">
        <img src={aboutData.image} alt={aboutData.name} />
      </div>
      <div className="col-md-9">
        <h2 className="mt-4 mt-md-0 mb-4">Erandi Suli, </h2>
        <p className="mb-0">{aboutData.aboutMe}</p>
        <div className="row my-4">
          <div className="col-md-6">
            <p className="mb-2">
              Phone: <span className="text-dark">{aboutData.phone}</span>
            </p>
            <p className="mb-0">
              Birthday: <span className="text-dark">{aboutData.birthday}</span>
            </p>
          </div>
          <div className="col-md-6 mt-2 mt-md-0 mt-sm-2">
            <p className="mb-0">
              Email: <span className="text-dark">{aboutData.email}</span>
            </p>
            <p className="mb-2">
              Location: <span className="text-dark">{aboutData.location}</span>
            </p>
          </div>
        </div>
        <Link to={aboutData.cvpath} className="btn btn-default mr-3">
          <i className="icon-cloud-download"></i>Download CV
        </Link>
        <ScrollLink
          activeclass="active"
          href="mailto:hello@erandisuli.dev"
          className="btn btn-alt mt-2 mt-md-0 mt-xs-2"
        >
          <i className="icon-envelope"></i>Hire me
        </ScrollLink>
      </div>
    </div>
  );
}

export default About;
