import React from "react";

function Education({ education: { year, degree, institution, content } }) {
    return (
        <div className="entry">
            <div className="title">
                <span>{year}</span>
            </div>
            <div className="body">
                <h3 className="mt-0">{degree}</h3>
                <h6 className="mt-1">{institution}</h6>
                <p>{content}</p>
            </div>
        </div>
    );
}

export default Education;